<template>
  <div class="flex items-center justify-center py-8 px-4">
    <div class="w-11/12 lg:w-2/3">
      <div class="flex h-full flex-col justify-between">
        <div>
          <div class="w-full justify-between lg:flex">
            <h3
              class="text-base font-bold leading-5 text-gray-600 dark:text-gray-400 md:text-xl"
            >
              Selling Overview
            </h3>
            <div
              class="mt-2 flex items-center justify-between md:mt-4 lg:mt-0 lg:justify-start"
            >
              <div class="flex items-center">
                <button
                  class="focus:outline-none bg-gray-100 py-2 px-4 text-xs text-gray-600 duration-150 ease-in hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-400"
                >
                  Dollars
                </button>
                <button
                  class="focus:outline-none bg-indigo-500 py-2 px-4 text-xs text-white duration-150 ease-in hover:bg-indigo-600"
                >
                  Tickets
                </button>
              </div>
              <div class="lg:ml-14">
                <div
                  class="rounded-sm bg-gray-100 px-3 pb-2 pt-1 duration-150 ease-in hover:bg-gray-200 dark:bg-gray-700"
                >
                  <select
                    class="focus:outline-none bg-transparent text-xs text-gray-600 dark:text-gray-400"
                  >
                    <option class="leading-1">Year</option>
                    <option class="leading-1">2020</option>
                    <option class="leading-1">2019</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-6 flex items-end">
            <h3 class="text-lg leading-5 text-indigo-500 md:text-2xl">
              $65,875
            </h3>
            <div class="ml-1 flex items-center md:ml-4">
              <p class="text-xs text-indigo-500 md:text-base">17%</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M6 2.5V9.5"
                  stroke="#4338CA"
                  stroke-width="0.75"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M8 4.5L6 2.5"
                  stroke="#4338CA"
                  stroke-width="0.75"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  d="M4 4.5L6 2.5"
                  stroke="#4338CA"
                  stroke-width="0.75"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <div class="mt-6">
          <canvas id="myChart" width="1025" height="400"></canvas>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
export default {
  name: "index",
  data() {
    return {
      chartId: "myChart",
      chartData: {
        type: "line",
        height: "366px",
        data: {
          labels: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "Aug",
            "Sep",
            "Nov",
            "Dec",
          ],
          datasets: [
            {
              label: "16 Mar 2018",
              borderColor: "#4A5568",
              data: [
                600, 400, 620, 300, 200, 600, 230, 300, 200, 200, 100, 1200,
              ],
              fill: false,
              pointBackgroundColor: "#4A5568",
              borderWidth: "3",
              pointBorderWidth: "4",
              pointHoverRadius: "6",
              pointHoverBorderWidth: "8",
              pointHoverBorderColor: "rgb(74,85,104,0.2)",
            },
          ],
        },
        options: {
          legend: {
            position: false,
          },
        },
      },
    };
  },
  mounted() {
    this.createChart(this.chartId, this.chartData);
  },
  methods: {
    createChart(chartId, chartData) {
      const ctx = document.getElementById(chartId);
      new Chart(ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options,
      });
    },
  },
};
</script>
